var site = site || {};
(function($) {
  Drupal.behaviors.navigation = {
    attach: function(context) {
      // Only run this nav stuff once:
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $win = $(window);
      var $body = $('body');
      var $nav = $('.site-nav--primary', context); // Static nav
      var $primaryNavMenus = $('.menu__list', $nav);
      var $util = $('.site-utils', context); // Fixed nav/utility bar
      var $utilInner = $('.site-utils__inner', $util);
      var $utilPromo = $('.site-utils__promo', $utilInner);
      var $utilMenu = $('.site-utils__menu', $utilInner);
      var $utilItems = $('.site-utils__item', context);
      var $utilDropdownLinks = $('.site-utils__item--dropdown', context);
      var $utilDropdownClose = $('.site-utils__dropdown__close', context);
      var $utilInputs = $('input', $util);
      var $navLinks = $('.menu__link--lvl-1, .menu__link--lvl-2', context);
      var $dropdownLinks = $navLinks.filter('.menu__link--has-children');
      var $footerSocialLinks = $('.site-footer-social-links__item a', context);

      var intvl;
      var navBottom = $nav.length ? $nav.offset().top + $nav.height() : 0;
      var stuck = false;
      var keepActiveUtilOpen = false;
      var st = $win.scrollTop();
      var initialScrollTop = st;
      var ignoreNativeBlur = false;
      var focusTimeout;
      var isMobile = Unison.fetch.now() && Unison.fetch.now().name === 'small';

      $primaryNavMenus.first().children().addClass('js-main-menu');

      $footerSocialLinks.on('click', function(e) {
        var href = e.target.host;
        $(document).trigger('social_link.click', href);
      });
      // This sets utility links to icons or text as needed to fit in the
      // viewport.
      function _checkUtilityLinks(event, showIcons) {
        var mw = $utilMenu.outerWidth(true); // menuWidth
        var as = $utilInner.width() - $utilPromo.outerWidth(true); // availableSpace
        var i = 0;
        var filter;
        var $items;
        var $item;

        // Reset everything for mobile:
        if (isMobile) {
          $utilItems.removeClass('site-utils__item--icon');
          $(document).trigger('checked-utility-links');
          return;
        }

        showIcons = typeof showIcons === 'undefined' ? mw > as : showIcons;
        while (((showIcons && mw > as) || (!showIcons && mw < as)) && i < 10) {
          i++;
          filter = '.site-utils__item--icon';
          if (showIcons) {
            filter = ':not(' + filter + ')';
          }
          $items = $utilItems.filter(filter);
          $item = showIcons ? $items.last() : $items.first();
          $item.toggleClass('site-utils__item--icon', showIcons);
          mw = $utilMenu.outerWidth(true);
        }
        // If we just showed text we have to check one more time to make sure
        // we're not overlapping.
        if (!showIcons) {
          _checkUtilityLinks(null, true);
          return;
        }

        $(document).trigger('checked-utility-links');
      }

      // This closes any active utility items on scroll:
      function _checkUtilScroll() {
        if (isMobile) {
          return;
        }
        if (!keepActiveUtilOpen) {
          $utilDropdownLinks.filter('.active').removeClass('active').trigger('changedActiveState');
          if (SelectBox) {
            SelectBox.prototype.hideMenus();
          }
        }
      }

      function _setSticky(event, force) {
        stickPos = isMobile ? 60 : 364;
        var animate = !isMobile && Modernizr.csstransitions && Modernizr.csstransforms;
        var to;
        st = $win.scrollTop();

        // Do nothing on touch devices when a utility nav input is focused,
        // since iOS will hide all fixed elements when the keyboard slides out,
        // even if that means hiding the input itself.
        if (Modernizr.touch && $utilInputs.filter(':focus').length) {
          return;
        }

        if ((!stuck || force) && st >= stickPos) {
          // Moves the sticky header up above the viewport with transform/translate:
          if (animate) {
            clearTimeout(to);
            // Temporarily disable animation:
            $body.removeClass('js-sticky-header--animate');
            setTimeout(function() {
              // Move the nav above the window:
              $body.addClass('js-sticky-header--animate--start');
              setTimeout(function() {
                // Turn animation back on:
                $body.addClass('js-sticky-header--animate');
                setTimeout(function() {
                  // Fix the sticky header, and remove the negative transform/translate:
                  $body.addClass('js-sticky-header js-sticky-header--animate--end sticky-header-opened').removeClass('js-sticky-header--animate--start');
                }, 10);
              }, 10);
            }, 10);
          } else {
            $body.addClass('js-sticky-header sticky-header-opened');
          }
          stuck = true;
        } else if (((stuck || force) && st < stickPos) && !keepActiveUtilOpen) {
          clearTimeout(to);
          // Remove the sticky header class after the animation
          if (animate) {
            // Enable animation:
            $body.addClass('js-sticky-header--animate js-sticky-header--animate--end');
            setTimeout(function() {
              // Add the negative transform/translate:
              $body.addClass('js-sticky-header--animate--start');
            }, 10);
            // Remove all the classes after the animation is complete
            to = setTimeout(function() {
              $body.removeClass('js-sticky-header js-sticky-header--animate js-sticky-header--animate--start js-sticky-header--animate--end sticky-header-opened');
            }, 300);
          } else {
            $body.removeClass('js-sticky-header js-sticky-header--animate js-sticky-header--animate--start js-sticky-header--animate--end sticky-header-opened');
          }
          stuck = false;
        }
      }

      function _load() {
        navBottom = $nav.length ? $nav.offset().top + $nav.height() : 0;
      }

      var _checkStuff = function() {
        _checkUtilScroll();
        _checkUtilityLinks();
      };

      $win.on('scroll', _.debounce(function() {
        _setSticky();
        _checkStuff();
      }, 100));

      $(document).on('load', _load);

      Unison.on('change', function() {
        isMobile = Unison.fetch.now().name === 'small';
        _setSticky(null, true);
        _checkUtilityLinks();
      });

      // This handles clicking the mobile nav toggle hamburger and clicking
      // outside the nav to close.
      $body.on('click', function(event) {
        var $tar = $(event.target);

        if ($tar.closest('.js-mobile-toggle-nav').length) {
          event.preventDefault();
          $body.toggleClass('js-nav-expanded');
          return;
        }
        if (!$tar.closest('.site-nav').length && $(this).hasClass('js-nav-expanded')) {
          event.preventDefault();
          $(this).removeClass('js-nav-expanded');
        }
      });

      // Utility Dropdowns
      if (!Modernizr.touch) {
        // Hover (desktop)
        var selectBoxFlag = false;
        var disableGnavOverlay = Drupal.settings.disable_gnav_signin_overlay ? Drupal.settings.disable_gnav_signin_overlay : false;
        if (!disableGnavOverlay) {
          $utilDropdownLinks.on('mouseover', function() {
            var $thismenu = $(this);
            var $childBlock = $thismenu.find('.site-utils__dropdown');
            site.restrict_navigation($childBlock);
            if ($thismenu.has('.selectbox.js-load-on-demand')) {
              $('.selectbox.js-load-on-demand:not(.loadOnDemand-processed)', $thismenu).trigger('selectbox:loadOnDemand');
            }
            $thismenu.not('.active').addClass('active').trigger('changedActiveState');
          }).on('mouseout', function() {
            var $thismenu = $(this);
            $thismenu.find('input').once().on('focus', function() {
              $thismenu.addClass('active-hold');
            });
            $thismenu.find('input').on('blur', function() {
              $thismenu.removeClass('active-hold');
            });
            $('.selectBox-options').each(function() {
              if ($(this).is('.selectBox-options-bottom , .selectBox-options-top')) {
                selectBoxFlag = true;
                return false;
              } else {
                selectBoxFlag = false;
              }
            });
            if (selectBoxFlag === false) {
              $(this).filter('.active').removeClass('active').trigger('changedActiveState');
            }
          });
        }

        var $target = $('.js-site-utils__item--dropdown a.js-site_utils');
        $target.keydown(function(e) {
          if (site.getKeycode(e) === 13) {
            e.preventDefault();
            var $this = $(this);
            var $utilLinks = $this.parents('.js-site-utils__item--dropdown');
            $this.first().attr('aria-expanded', 'true');
            $utilLinks.trigger('mouseover');
            $('.js-site-utils-dropdown-content').focus();
          }
          if (e.shiftKey) {
            $(this).parent().removeClass('active');
            $(this).first().attr('aria-expanded', 'false');
          }
        });
      } else {
        // Click (for touch devices)
        $('.site-utils__link', $utilDropdownLinks).on('click', function(event) {
          var $dropDown = $(this).parents('.site-utils__item--dropdown');
          // If the drop down has a form in it just follow the link, forms in
          // these overlays are pretty much impossible for touch devices above
          // mobile.
          if (Unison.fetch.now().name !== 'small' && $dropDown.has('form')) {
            return;
          }

          // Do nothing if clicking on the close button
          if ($(event.target).closest('.site-utils__dropdown__close').length) {
            return;
          }

          if (!$dropDown.hasClass('active')) {
            $utilDropdownLinks.filter('.active').removeClass('active').trigger('changedActiveState');
          }

          $dropDown.toggleClass('active').trigger('changedActiveState');

          event.preventDefault();
        });

        // Close util dropdowns when clicking outside of them:
        $body.on('touchstart click', function(event) {
          if (!$(event.target).parents('.site-utils__item--dropdown').length) {
            $utilDropdownLinks.filter('.active').removeClass('active').trigger('changedActiveState');
          }
        });

        // Inputs in sticky nav for touch devices. This is way harder than it
        // seems.
        $utilInputs.on('touchstart', function() {
          if (!stuck) {
            return;
          }
          var $focused = $utilInputs.filter(':focus');
          if ($focused.length) {
            ignoreNativeBlur = true;
            $focused.trigger('bbNavBlur');
          }
          $(this).trigger('bbNavFocusStart');
        }).on('focus', function() {
          if (!stuck) {
            return;
          }
          $(this).trigger('bbNavFocusEnd');
        }).on('blur', function() {
          if (!stuck) {
            return;
          }
          if (!ignoreNativeBlur) {
            $(this).trigger('bbNavBlur');
          }
          ignoreNativeBlur = false;
        });

        // On blur, reset everything:
        $utilInputs.on('bbNavBlur', function() {
          $util.css({
            position: '',
            top: ''
          });
          keepActiveUtilOpen = false;
          _setSticky(null, true);
        });

        // On beginning the focus, set sticky nav to position absolute:
        $utilInputs.on('bbNavFocusStart', function() {
          initialScrollTop = st;
          $util.css({
            position: 'absolute',
            top: initialScrollTop
          });
          keepActiveUtilOpen = true;
        });

        // On ending the focus, scroll back to the position we were at when we
        // started focusing:
        $utilInputs.on('bbNavFocusEnd', function() {
          $(window).scrollTop(initialScrollTop);
          // Timeout is set to get around the _checkStuff debounce.
          focusTimeout = setTimeout(function() {
            keepActiveUtilOpen = false;
          }, 500);
        });

        $utilDropdownLinks.on('changedActiveState', function() {
          setTimeout(function() {
            $utilInputs.filter(':focus').trigger('blur');
          }, 300);
        });
      }

      $utilDropdownClose.on('touchstart click keydown', function(e) {
        var keyCode = site.getKeycode(e);
        var $this = $(this);
        if (keyCode === 1 || keyCode === 13) {
          e.preventDefault();
          $this.closest('li').find('.js-site_utils').attr('aria-expanded', 'false').focus();
          var $closestDropdown = $this.closest('.js-site-utils__item--dropdown');
          $closestDropdown.removeClass('active').trigger('changedActiveState');
        }
      });

      if (Unison.fetch.now()) {
        isMobile = Unison.fetch.now().name === 'small';
        _checkUtilityLinks();
        _setSticky();
      }

      // For the mobile primary nav:
      $dropdownLinks.on('click', function(event) {
        event.preventDefault();
        if (isMobile) {
          $(this).closest('.menu__item').toggleClass('menu__item--selected');
        }
      });

      // TODO:
      // 1) Why is this here? Should I always remove the class, or should it toggle it?
      // 2) Where is the "navigation_control" element located? I dont' see it anywhere.
      // 3) Most 'click' events should use the once() plugin
      $('#navigation_control').click(function() {
        $body.removeClass('js-nav-expanded');
      });

      $navLinks.focus(function() {
        $(this).trigger('hover');
        if ($(this).hasClass('menu__link--has-children')) {
          $(this).attr('aria-expanded', 'false');
        }
      });

      $navLinks.hover(
        function() {
          $(document).trigger('main_navigation.item.hover.over', $(this));
        },
        function() {
          $(document).trigger('main_navigation.item.hover.off', $(this));
        }
      );

      $navLinks.click(function(e) {
        $(document).trigger('main_navigation.item.click', e.target.innerText);
      });
    },
    attached: false
  };

  Drupal.behaviors.loopOffers = {
    attach: function(context) {
      var $promotionContainer = $('.site-utils__promo', context);
      var $promos = $('.utility-promo', $promotionContainer);
      var isInCarousel = $promos.closest('.js-basic-carousel').length > 0;
      if (isInCarousel || $promos.length < 2) {
        return;
      }

      setInterval(function() {
        // Have to re-select .utility-promo rather than use $promos because
        // we're changing the order.
        $('.utility-promo', $promotionContainer).first().slideUp(function() {
          $(this).appendTo($(this).parent()).css({ overflow: '', display: '' });
        });
      }, 3000);
    }
  };
})(jQuery);
